import React, { Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, HashRouter } from 'react-router-dom'
import './scss/style.scss'
import DefaultLayout from './layout/DefaultLayout'
import PropTypes from 'prop-types'
import CryptoJS from 'crypto-js'

const SECRET_KEY = 'A5utyw+*.er.gfgh.rwthsrrt..th.s7th.8.0werg*r*gwe7***86wr.sh.e.rn..f***gero'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Terminos = React.lazy(() => import('./views/pages/terminos/Terminos'))

const App = () => {
  // Función para cifrar el valor
  const encryptValue = (value) => {
    return CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString()
  }

  // Función para descifrar el valor
  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY)
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  }

  // Estado inicial obtenido del localStorage
  const [isLogged, setIsLogged] = useState(() => {
    if (localStorage.getItem('isLogged')) {
      const encryptedLogged = localStorage.getItem('isLogged')
      return encryptedLogged ? decryptValue(encryptedLogged) : false
    } else {
      return false
    }
  })

  // Efecto para almacenar el estado cifrado en el localStorage
  useEffect(() => {
    const encryptedValue = encryptValue(isLogged)
    localStorage.setItem('isLogged', encryptedValue)
  }, [isLogged])

  // Función para actualizar el estado de isLogged
  const setIsUserLoggedIn = (loggedIn) => {
    setIsLogged(loggedIn)
  }

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/login" element={<Login setIsUserLoggedIn={setIsUserLoggedIn} />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/404" element={<Page404 />} />
          <Route exact path="/500" element={<Page500 />} />
          <Route exact path="/terminos" element={<Terminos />} />
          <Route path="*" name="Home" element={<PrivateRoute isLogged={isLogged} />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

const PrivateRoute = ({ isLogged }) => {
  if (isLogged) {
    return (
      <Routes>
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    )
  } else {
    return <Navigate to="/login" replace />
  }
}

// Añade la validación de PropTypes para isLogged
PrivateRoute.propTypes = {
  isLogged: PropTypes.bool.isRequired,
}

export default App
