import React, { useState, useEffect } from 'react'
import CIcon from '@coreui/icons-react'
import * as icons from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Picks',
    to: '/picks',
    icon: <CIcon icon={icons.cilAlarm} customClassName="nav-icon" />,
  },
  /*{
    component: CNavItem,
    name: 'Validación Pick',
    to: '/picksgestion',
    icon: <CIcon icon={icons.cilCheck} customClassName="nav-icon" />,
  },*/
  {
    component: CNavItem,
    name: 'Histórico',
    to: '/historico',
    icon: <CIcon icon={icons.cilCalendar} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Perfil',
    to: '/perfil',
    icon: <CIcon icon={icons.cilUser} customClassName="nav-icon" />,
  },
]

export default _nav
