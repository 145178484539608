import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CAvatar, CDropdown, CDropdownToggle } from '@coreui/react'
import logout from './../../assets/images/logout.png'
import logo_l_blue from './../../assets/images/logos/biltek_horizontal_azul.png'

const AppHeaderDropdown = () => {
  const logoutFunction = () => {
    // Aquí puedes agregar cualquier lógica de cierre de sesión necesaria
    // Por ejemplo, limpiar el almacenamiento local, eliminar tokens de sesión, etc.
    localStorage.removeItem('isLogged')

    window.location.reload()
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar src={logout} size="sd" onClick={logoutFunction} />
      </CDropdownToggle>
    </CDropdown>
  )
}

export default AppHeaderDropdown
