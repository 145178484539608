import React from 'react'

const Picks = React.lazy(() => import('./views/picks/Picks'))
const PicksGestion = React.lazy(() => import('./views/picksgestion/PicksGestion'))
const Historico = React.lazy(() => import('./views/historico/historico'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/picks', name: 'Picks', element: Picks },
  { path: '/picksgestion', name: 'Validación de picks', element: PicksGestion },
  { path: '/historico', name: 'Gestión de picks', element: Historico },
]

export default routes
