import React from 'react'
import RoganetLogo from 'src/assets/images/logos/roganet_azul.png'
import Autoexclusion from 'src/assets/images/autoexclusion.png'
import Menores from 'src/assets/images/18.png'
import { CFooter } from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import './footer.css'

const AppFooter = () => {
  const navigate = useNavigate()
  var ano = new Date().getFullYear()
  return (
    <CFooter>
      <div className="footer-container">
        <a
          href="http://www.roganet.es"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          <img
            src={RoganetLogo}
            alt="Logo"
            height={23}
            className="footer-logo"
            style={{ marginTop: 5 }}
          />
          <strong>&nbsp;&nbsp;&copy;&nbsp;&nbsp;{ano}.</strong>
          &nbsp;
          <span className="footer-rights">All rights reserved.</span>
        </a>
        <div className="footer-right">
          <a
            onClick={() => navigate('/terminos')}
            className="footer-terms"
            style={{ fontSize: 14 }}
          >
            Términos y Condiciones
          </a>
          <a
            onClick={() => navigate('/terminos')}
            className="footer-terms"
            style={{ fontSize: 14 }}
          >
            Aviso Legal
          </a>
          <a
            onClick={() => navigate('/terminos')}
            className="footer-terms"
            style={{ fontSize: 14 }}
          >
            Política de Privacidad y Cookies
          </a>
          <div className="footer-images">
            <img src={Menores} style={{ height: 33 }} />
            <img src={Autoexclusion} style={{ height: 32 }} />
          </div>
        </div>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
