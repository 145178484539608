import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderToggler,
  CHeaderNav,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CForm,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'
import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import img1 from 'src/assets/images/casas/1.png'
import img2 from 'src/assets/images/casas/2.png'
import img3 from 'src/assets/images/casas/3.png'
import img4 from 'src/assets/images/casas/4.png'
import img5 from 'src/assets/images/casas/5.png'
import img6 from 'src/assets/images/casas/6.png'
import img7 from 'src/assets/images/casas/7.png'
import img8 from 'src/assets/images/casas/8.png'
import img9 from 'src/assets/images/casas/9.png'
import img10 from 'src/assets/images/casas/10.png'

const MySwal = withReactContent(Swal)

const imageMap = {
  1: img1,
  2: img2,
  3: img3,
  4: img4,
  5: img5,
  6: img6,
  7: img7,
  8: img8,
  9: img9,
  10: img10,
}

const AppHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [cashValue, setCashValue] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [casas, setCasas] = useState([])
  const [numCasas, setNumCasas] = useState(0)

  const API_BASE_URL = 'http://wisebet.bet/api'

  const getCash = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/DevolverCash?id_usuario=${localStorage.getItem('username')}`,
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const result = await response.json()
      console.log(result.cash)
      setCashValue(new Number(result.cash).toFixed(2))
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error)
    }
  }

  const togglePrioritario = (index) => {
    const updatedCasas = [...casas]
    updatedCasas[index].prioritario = updatedCasas[index].prioritario == 1 ? 0 : 1
    setCasas(updatedCasas)
    setNumCasas(updatedCasas.filter((casa) => casa.prioritario == 1).length)
  }

  const guardarConfCasas = async () => {
    const shops = casas.map((casa) => ({
      id_shop: casa.id_shop,
      prioritario: casa.prioritario == 1,
    }))

    await updateShopsForUser(localStorage.getItem('username'), shops)

    MySwal.fire({
      icon: 'success',
      title: 'Guardado',
      text: 'Las configuraciones de las casas se han guardado correctamente.',
      showConfirmButton: false,
      timer: 1000,
    })

    console.log(casas)

    setIsModalOpen(false)
  }

  const fetchCasas = async () => {
    try {
      const response = await fetch(
        'http://wisebet.bet/api/UsuariosCasasApuestas?id_usuario=' +
          localStorage.getItem('username'),
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const result = await response.json()
      setCasas(result)
      setNumCasas(result.filter((casa) => casa.prioritario == 1).length)
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error)
    }
  }

  useEffect(() => {
    getCash()
    fetchCasas()
  }, [])

  useEffect(() => {
    fetchCasas()
  }, [isModalOpen])

  const textColor = cashValue >= 0 ? 'green' : 'red'

  const renderRows = () => {
    const rows = []
    for (let i = 0; i < casas.length; i += 4) {
      rows.push(
        <CTableRow key={i}>
          {casas.slice(i, i + 4).map((item, index) => (
            <CTableDataCell key={index} style={{ textAlign: 'center' }}>
              <div onClick={() => togglePrioritario(i + index)} style={{ cursor: 'pointer' }}>
                <img
                  src={imageMap[item.id_shop]}
                  alt={`Casa ${item.id_shop}`}
                  style={{
                    width: '60px',
                    height: '60px',
                    opacity: item.prioritario == 1 ? 1 : 0.2,
                  }}
                />
              </div>
            </CTableDataCell>
          ))}
        </CTableRow>,
      )
    }
    return rows
  }

  return (
    <CHeader position="sticky" className="mb-3">
      <CContainer fluid>
        <div>
          <CHeaderToggler
            className="ps-1"
            onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          >
            <CIcon icon={cilMenu} size="xl" />
          </CHeaderToggler>
          <CHeaderToggler>
            <AppBreadcrumb />
          </CHeaderToggler>
        </div>
        <CHeaderNav className="ms-3">
          <CButton
            color="dark"
            onClick={() => {
              setIsModalOpen(true)
            }}
          >
            Casas ({numCasas})
          </CButton>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label style={{ fontSize: 25 }}>
            cash(€)&nbsp;
            <b style={{ color: textColor }}>{cashValue}</b>
          </label>
          &nbsp;&nbsp;&nbsp;
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CModal visible={isModalOpen} backdrop="static" onDismiss={() => setIsModalOpen(false)}>
        <CModalHeader closeButton onClick={() => setIsModalOpen(false)}>
          <b>Casas de apuestas</b>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <CTable borderless>
              <CTableBody>{renderRows()}</CTableBody>
            </CTable>
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setIsModalOpen(false)}>
            Cerrar
          </CButton>
          <CButton color="secondary" onClick={guardarConfCasas}>
            Guardar
          </CButton>
        </CModalFooter>
      </CModal>
    </CHeader>
  )
}

export default AppHeader

// Función para actualizar las tiendas de un usuario
const updateShopsForUser = async (userId, shops) => {
  try {
    const response = await fetch('http://wisebet.bet/api/InsertarUsuariosCasasApuestas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Si necesitas autenticación
      },
      body: JSON.stringify({
        id_usuario: userId,
        shops: shops,
      }),
    })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()
    console.log('Success:', data)
  } catch (error) {
    console.error('Error:', error)
  }
}
